@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-Thin.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-Thin.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-ThinItalic.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-ThinItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-ExtraLight.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-ExtraLight.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 200;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-ExtraLightItalic.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-ExtraLightItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-Light.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-Light.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-LightItalic.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-LightItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-Regular.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-Regular.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-Italic.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-Italic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-Medium.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-Medium.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-MediumItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-SemiBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-Bold.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-Bold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-BoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-ExtraBold.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-Black.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-Black.woff?v=3.19") format("woff");
}
@font-face {
  font-family: "Inter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("/assets/fonts/inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"), url("/assets/fonts/inter/Inter-BlackItalic.woff?v=3.19") format("woff");
}
/* -------------------------------------------------------
Variable font.
Usage:

  html { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    html { font-family: 'Inter var', sans-serif; }
  }
*/
@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Regular";
  src: url("/assets/fonts/inter/Inter-roman.var.woff2?v=3.19") format("woff2");
}
@font-face {
  font-family: "Inter var";
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url("/assets/fonts/inter/Inter-italic.var.woff2?v=3.19") format("woff2");
}
/* --------------------------------------------------------------------------
[EXPERIMENTAL] Multi-axis, single variable font.

Slant axis is not yet widely supported (as of February 2019) and thus this
multi-axis single variable font is opt-in rather than the default.

When using this, you will probably need to set font-variation-settings
explicitly, e.g.

  * { font-variation-settings: "slnt" 0deg }
  .italic { font-variation-settings: "slnt" 10deg }

*/
@font-face {
  font-family: "Inter var experimental";
  font-weight: 100 900;
  font-display: swap;
  font-style: oblique 0deg 10deg;
  src: url("/assets/fonts/inter/Inter.var.woff2?v=3.19") format("woff2");
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--body-font-family);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: var(--body-color);
  background-color: var(--body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: var(--headings-margin-bottom);
  line-height: var(--headings-line-height);
  color: var(--headings-color);
}

h1 {
  font-size: var(--h1-font-size);
}

h2 {
  font-size: var(--h2-font-size);
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
}

h6 {
  font-size: var(--h6-font-size);
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.2em;
  background-color: var(--mark-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: var(--link-color);
  text-decoration: underline;
}
a:hover {
  color: var(--link-hover-color);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--font-family-code);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  color: var(--pre-color);
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2em 0.4em;
  font-size: 0.875em;
  color: var(--kbd-color);
  background-color: var(--kbd-bg);
  border-radius: var(--kbd-border-radius);
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: var(--table-cell-padding-y);
  padding-bottom: var(--table-cell-padding-y);
  color: var(--table-caption-color);
  text-align: left;
}

th {
  font-weight: 500;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.btn {
  display: inline-block;
  line-height: var(--line-height-base);
  padding: 0.375rem 0.9375rem;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--btn-border-radius);
  font-size: 0.875rem;
  text-align: center;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-color: var(--btn-border);
  background-color: var(--btn-bg);
  box-shadow: var(--btn-box-shadow);
  font-weight: var(--btn-font-weight);
  color: var(--btn-color);
  min-height: 2.625rem;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  border-color: var(--btn-hover-border);
  background-color: var(--btn-hover-bg);
  color: var(--btn-hover-color);
}
.btn-check:focus-visible + .btn, .btn:focus-visible {
  box-shadow: var(--btn-focus-box-shadow);
  outline: 0;
}
.btn-check:checked + .btn, .btn-check.active + .btn, .btn-check:active + .btn, .btn.active, .btn:active {
  color: var(--btn-active-color);
  background-color: var(--btn-active-bg);
  border-color: var(--btn-active-border);
}
.btn-check:checked + .btn:focus, .btn-check.active + .btn:focus, .btn-check:active + .btn:focus, .btn.active:focus, .btn:active:focus {
  box-shadow: var(--btn-active-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--btn-disabled-color);
  background-color: var(--btn-disabled-bg);
  border-color: var(--btn-disabled-border);
  pointer-events: none;
  box-shadow: none;
}

.btn-primary {
  --btn-bg: var(--btn-primary-bg);
  --btn-border: var(--btn-primary-border);
  --btn-color: var(--btn-primary-color);
  --btn-box-shadow: var(--btn-primary-box-shadow);
  --btn-font-weight: var(--btn-primary-font-weight);
  --btn-hover-bg: var(--btn-primary-hover-bg);
  --btn-hover-border: var(--btn-primary-hover-border);
  --btn-hover-color: var(--btn-primary-hover-color);
  --btn-active-bg: var(--btn-primary-active-bg);
  --btn-active-border: var(--btn-primary-active-border);
  --btn-active-color: var(--btn-primary-active-color);
  --btn-active-box-shadow: var(--btn-primary-active-box-shadow);
  --btn-focus-box-shadow: var(--btn-primary-focus-box-shadow);
  --btn-disabled-bg: var(--btn-primary-disabled-bg);
  --btn-disabled-border: var(--btn-primary-disabled-border);
  --btn-disabled-color: var(--btn-primary-disabled-color);
}

.btn-danger {
  --btn-bg: var(--btn-danger-bg);
  --btn-border: var(--btn-danger-border);
  --btn-color: var(--btn-danger-color);
  --btn-box-shadow: var(--btn-danger-box-shadow);
  --btn-font-weight: var(--btn-danger-font-weight);
  --btn-hover-bg: var(--btn-danger-hover-bg);
  --btn-hover-border: var(--btn-danger-hover-border);
  --btn-hover-color: var(--btn-danger-hover-color);
  --btn-active-bg: var(--btn-danger-active-bg);
  --btn-active-border: var(--btn-danger-active-border);
  --btn-active-color: var(--btn-danger-active-color);
  --btn-active-box-shadow: var(--btn-danger-active-box-shadow);
  --btn-focus-box-shadow: var(--btn-danger-focus-box-shadow);
  --btn-disabled-bg: var(--btn-danger-disabled-bg);
  --btn-disabled-border: var(--btn-danger-disabled-border);
  --btn-disabled-color: var(--btn-danger-disabled-color);
}

.btn-danger-outline {
  --btn-bg: var(--btn-danger-outline-bg);
  --btn-border: var(--btn-danger-outline-border);
  --btn-color: var(--btn-danger-outline-color);
  --btn-box-shadow: var(--btn-danger-outline-box-shadow);
  --btn-font-weight: var(--btn-danger-outline-font-weight);
  --btn-hover-bg: var(--btn-danger-outline-hover-bg);
  --btn-hover-border: var(--btn-danger-outline-hover-border);
  --btn-hover-color: var(--btn-danger-outline-hover-color);
  --btn-active-bg: var(--btn-danger-outline-active-bg);
  --btn-active-border: var(--btn-danger-outline-active-border);
  --btn-active-color: var(--btn-danger-outline-active-color);
  --btn-active-box-shadow: var(--btn-danger-outline-active-box-shadow);
  --btn-focus-box-shadow: var(--btn-danger-outline-focus-box-shadow);
  --btn-disabled-bg: var(--btn-danger-outline-disabled-bg);
  --btn-disabled-border: var(--btn-danger-outline-disabled-border);
  --btn-disabled-color: var(--btn-danger-outline-disabled-color);
}

.btn-outline {
  --btn-bg: var(--btn-outline-bg);
  --btn-border: var(--btn-outline-border);
  --btn-color: var(--btn-outline-color);
  --btn-box-shadow: var(--btn-outline-box-shadow);
  --btn-font-weight: var(--btn-outline-font-weight);
  --btn-hover-bg: var(--btn-outline-hover-bg);
  --btn-hover-border: var(--btn-outline-hover-border);
  --btn-hover-color: var(--btn-outline-hover-color);
  --btn-active-bg: var(--btn-outline-active-bg);
  --btn-active-border: var(--btn-outline-active-border);
  --btn-active-color: var(--btn-outline-active-color);
  --btn-active-box-shadow: var(--btn-outline-active-box-shadow);
  --btn-focus-box-shadow: var(--btn-outline-focus-box-shadow);
  --btn-disabled-bg: var(--btn-outline-disabled-bg);
  --btn-disabled-border: var(--btn-outline-disabled-border);
  --btn-disabled-color: var(--btn-outline-disabled-color);
}

.btn-outline2 {
  --btn-bg: var(--btn-outline2-bg);
  --btn-border: var(--btn-outline2-border);
  --btn-color: var(--btn-outline2-color);
  --btn-box-shadow: var(--btn-outline2-box-shadow);
  --btn-font-weight: var(--btn-outline2-font-weight);
  --btn-hover-bg: var(--btn-outline2-hover-bg);
  --btn-hover-border: var(--btn-outline2-hover-border);
  --btn-hover-color: var(--btn-outline2-hover-color);
  --btn-active-bg: var(--btn-outline2-active-bg);
  --btn-active-border: var(--btn-outline2-active-border);
  --btn-active-color: var(--btn-outline2-active-color);
  --btn-active-box-shadow: var(--btn-outline2-active-box-shadow);
  --btn-focus-box-shadow: var(--btn-outline2-focus-box-shadow);
  --btn-disabled-bg: var(--btn-outline2-disabled-bg);
  --btn-disabled-border: var(--btn-outline2-disabled-border);
  --btn-disabled-color: var(--btn-outline2-disabled-color);
}

.btn-link {
  --btn-bg: var(--btn-link-bg);
  --btn-border: var(--btn-link-border);
  --btn-color: var(--btn-link-color);
  --btn-box-shadow: var(--btn-link-box-shadow);
  --btn-font-weight: var(--btn-link-font-weight);
  --btn-hover-bg: var(--btn-link-hover-bg);
  --btn-hover-border: var(--btn-link-hover-border);
  --btn-hover-color: var(--btn-link-hover-color);
  --btn-active-bg: var(--btn-link-active-bg);
  --btn-active-border: var(--btn-link-active-border);
  --btn-active-color: var(--btn-link-active-color);
  --btn-active-box-shadow: var(--btn-link-active-box-shadow);
  --btn-focus-box-shadow: var(--btn-link-focus-box-shadow);
  --btn-disabled-bg: var(--btn-link-disabled-bg);
  --btn-disabled-border: var(--btn-link-disabled-border);
  --btn-disabled-color: var(--btn-link-disabled-color);
}

.btn-lg {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--card-bg-color);
  background-clip: border-box;
  border: var(--card-border-width) solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  color: var(--card-text-color);
}
.card .card-preloader {
  border-radius: var(--card-border-radius);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card-tabs {
  padding: 0 var(--card-spacer-x);
  background-color: var(--card-tabs-bg-color);
}

.card-body {
  flex: 1 1 auto;
  padding: calc(var(--card-spacer-y) - var(--card-border-width)) calc(var(--card-spacer-x) - var(--card-border-width));
  color: var(--card-text-color);
}

.card-title {
  margin-bottom: var(--card-spacer-y);
}

.card-subtitle {
  margin-top: calc(var(--card-spacer-y) * -0.5);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--card-spacer-x);
}

.card-header {
  padding: calc(var(--card-spacer-y) * 0.5) var(--card-spacer-x);
  margin-bottom: 0;
  color: var(--card-header-text-color);
  background-color: var(--card-header-bg-color);
  border-bottom: var(--card-border-width) solid var(--card-border-color);
}
.card-header:first-child {
  border-radius: calc(var(--card-border-radius) - var(--card-border-width)) calc(var(--card-border-radius) - var(--card-border-width)) 0 0;
}

.card-footer {
  padding: calc(var(--card-spacer-y) * 0.5) var(--card-spacer-x);
  color: var(--card-footer-text-color);
  background-color: var(--card-footer-bg-color);
  border-top: var(--card-border-width) solid var(--card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 calc(var(--card-border-radius) - var(--card-border-width)) calc(var(--card-border-radius) - var(--card-border-width));
}

.card > .table > :not(caption) > * > *:first-child,
.table-in-card > :not(caption) > * > *:first-child {
  padding-left: var(--card-spacer-x);
}
.card > .table > :not(caption) > * > *:last-child,
.table-in-card > :not(caption) > * > *:last-child {
  padding-right: var(--card-spacer-x);
}

.card > .table:first-child tr:first-child td:first-child {
  border-bottom-left-radius: var(--card-border-radius);
}
.card > .table:first-child tr:first-child td:last-child {
  border-bottom-right-radius: var(--card-border-radius);
}
.card > .table:last-child tr:last-child td:first-child {
  border-bottom-left-radius: var(--card-border-radius);
}
.card > .table:last-child tr:last-child td:last-child {
  border-bottom-right-radius: var(--card-border-radius);
}

.card > aeb-list-group {
  --list-group-item-padding-x: var(--card-spacer-x);
  --list-group-border-radius: var(--card-border-radius);
}

.card-tabs > .nav-tabs {
  --nav-link-color: var(--card-tabs-link-color);
  --nav-tabs-items-gap: 0.75rem;
  --nav-tabs-link-padding-x: 0.78125rem;
  --nav-tabs-link-padding-y: 1rem;
  --nav-tabs-link-border-width: 6px;
  --nav-tabs-link-active-color: var(--card-tabs-link-active-color);
  margin-left: -0.25rem;
  margin-right: -0.25rem;
  padding-bottom: var(--nav-tabs-link-border-width);
}
.card-tabs > .nav-tabs .nav-link {
  font-weight: normal;
  text-transform: none;
}

.card-preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1060;
  transform: translate3d(0, 0, 0);
  overflow: hidden;
}
.card-preloader aeb-backdrop {
  --backdrop-width: 100%;
  --backdrop-height: 100%;
}

.circle {
  --circle-size: 0.625rem;
  display: inline-block;
  width: var(--circle-size);
  height: var(--circle-size);
  padding: 0;
  border-radius: 50%;
}

.container-fluid {
  width: 100%;
  padding-right: var(--gutter-x, 0.75rem);
  padding-left: var(--gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.dropdown {
  --dropdown-border-width: 0;
  --dropdown-border-radius: var(--border-radius);
  --dropdown-spacer-y: 0.7875rem;
  --dropdown-spacer-x: 0.7875rem;
  --dropdown-item-spacer-y: 0.25rem;
  --dropdown-item-spacer-x: var(--dropdown-spacer-x);
  --dropdown-caption-spacer-y: var(--dropdown-item-spacer-y);
  --dropdown-caption-spacer-x: var(--dropdown-item-spacer-x);
  --dropdown-footer-spacer-y: 0.5rem;
  position: relative;
  padding: var(--dropdown-spacer-y) var(--dropdown-spacer-x);
  background-color: var(--dropdown-bg);
  border: var(--dropdown-border-width) solid var(--dropdown-border);
  border-radius: var(--dropdown-border-radius);
  filter: drop-shadow(var(--dropdown-box-shadow));
  overflow: hidden;
  white-space: nowrap;
  color: var(--dropdown-color);
}
.dropdown-divider {
  height: 1px;
  margin: var(--dropdown-spacer-y) calc(var(--dropdown-spacer-x) * -1);
  background-color: var(--dropdown-divider-color);
}
.dropdown-caption {
  line-height: 1.28571;
  padding: var(--dropdown-caption-spacer-y) var(--dropdown-caption-spacer-x);
  margin: 0 calc(var(--dropdown-spacer-x) * -1);
  font-weight: 600;
}
.dropdown-item {
  line-height: 1.28571;
  padding: var(--dropdown-item-spacer-y) var(--dropdown-item-spacer-x);
  margin: 0 calc(var(--dropdown-spacer-x) * -1);
  cursor: pointer;
  background-color: var(--dropdown-item-bg);
  color: var(--dropdown-item-color);
}
.dropdown-item:hover, .dropdown-item:focus {
  background-color: var(--dropdown-item-hover-bg);
  text-decoration: none;
  color: var(--dropdown-item-hover-color);
}
.dropdown-item.active, .dropdown-item:active {
  background-color: var(--dropdown-item-active-bg);
  text-decoration: none;
  color: var(--dropdown-item-active-color);
}
.dropdown-footer {
  padding-top: var(--dropdown-footer-spacer-y);
  padding-bottom: var(--dropdown-footer-spacer-y);
}

.dropdown-menu {
  --dropdown-spacer-y: 0.75rem;
  --dropdown-spacer-x: 1.25rem;
}

.form-label {
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
  font-weight: 600;
  color: var(--form-label-color);
}
.form-label[for] {
  cursor: pointer;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 600;
  line-height: var(--line-height-base);
  color: var(--form-label-color);
}

.form-text {
  font-size: 0.75rem;
  color: var(--form-text-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: var(--line-height-base);
  color: var(--input-color);
  background-color: var(--input-bg);
  background-clip: padding-box;
  border: 1px solid var(--input-border);
  border-radius: var(--input-border-radius);
  box-shadow: var(--input-box-shadow);
  appearance: none;
  min-height: 2.625rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control:focus {
  border-color: var(--input-focus-border);
  background-color: var(--input-focus-bg);
  color: var(--input-focus-color);
  outline: 0;
}
.form-control::-webkit-date-and-time-value {
  height: calc(var(--line-height-base) * 1em);
}
.form-control::placeholder {
  color: var(--input-placeholder-color);
  opacity: 1;
}
.form-control::-ms-reveal {
  display: none;
}
.form-control.disabled, .form-control:disabled, .form-control[readonly] {
  border-color: var(--input-disabled-border);
  background-color: var(--input-disabled-bg);
  color: var(--input-disabled-color);
  opacity: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: var(--line-height-base);
  color: var(--input-plaintext-color);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
  box-shadow: var(--input-box-shadow);
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

textarea.form-control {
  min-height: calc(var(--line-height-base) * 1em + 0.75rem + 2px);
}

.form-control-with-icons {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
.form-control-with-icons .icons {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  padding: 0 0.25rem;
  color: var(--text-color-3);
}
.form-control-with-icons .icons heroicon,
.form-control-with-icons .icons svg-icon {
  display: flex;
  align-items: center;
  width: 2rem;
  padding: 0 0.25rem;
}

.form-input-number {
  position: relative;
}
.form-input-number__input {
  padding-right: 5rem;
}
.form-input-number__controls {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.625rem;
}
.form-input-number__of {
  pointer-events: none;
  font-size: 1rem;
  color: var(--form-input-number-of-color);
}
.form-input-number__buttons {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-direction: column;
  height: 100%;
  padding-top: calc(1px + 0.125rem);
  padding-bottom: calc(1px + 0.125rem);
  padding-right: calc(1px + 0.125rem);
}
.form-input-number__button {
  flex-basis: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  line-height: 0;
  padding: 0;
  border: 0;
  background-color: var(--form-input-number-button-bg);
  color: var(--form-input-number-button-color);
}
.form-input-number__button .heroicon {
  width: 0.825rem;
}
.form-input-number__button:not(:disabled):hover {
  background-color: var(--form-input-number-button-hover-bg);
  color: var(--form-input-number-button-hover-color);
}
.form-input-number__button:not(:disabled):focus, .form-input-number__button:not(:disabled):active {
  background-color: var(--form-input-number-button-focus-bg);
  color: var(--form-input-number-button-focus-color);
}
.form-input-number__button.increase-btn {
  border-top-left-radius: calc(var(--input-border-radius) * 0.5);
  border-top-right-radius: calc(var(--input-border-radius) * 0.5);
}
.form-input-number__button.decrease-btn {
  border-bottom-left-radius: calc(var(--input-border-radius) * 0.5);
  border-bottom-right-radius: calc(var(--input-border-radius) * 0.5);
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: var(--line-height-base);
  color: var(--form-select-color);
  background-color: var(--form-select-bg);
  background-image: var(--form-select-indicator-bg-image);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid var(--form-select-border);
  border-radius: var(--form-select-border-radius);
  box-shadow: var(--form-select-box-shadow);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
  min-height: 2.625rem;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: var(--form-select-focus-border);
  outline: 0;
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select.disabled, .form-select:disabled {
  border-color: var(--form-select-disabled-border);
  background-color: var(--form-select-disabled-bg);
  color: var(--form-select-disabled-color);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--form-select-color);
}

.form-check {
  display: block;
  min-height: calc(var(--font-size-base) * var(--line-height-base));
  padding-left: 1.5rem;
  margin-bottom: 0;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5rem;
}

.form-check-input {
  width: 1rem;
  height: 1rem;
  margin-top: calc((var(--line-height-base) * var(--font-size-base) - 1rem) * 0.5);
  vertical-align: top;
  background-color: var(--form-check-input-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid var(--form-check-input-border);
  appearance: none;
  print-color-adjust: exact;
  transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type=checkbox] {
  border-radius: var(--form-check-input-border-radius);
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: var(--form-check-input-focus-border);
  outline: 0;
}
.form-check-input:checked {
  background-color: var(--form-check-input-checked-bg);
  border-color: var(--form-check-input-checked-border);
}
.form-check-input:checked[type=checkbox] {
  background-image: var(--form-check-input-checked-bg-image);
}
.form-check-input:checked[type=radio] {
  background-image: var(--form-check-radio-checked-bg-image);
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: var(--form-check-input-indeterminate-bg);
  border-color: var(--form-check-input-indeterminate-border);
  background-image: var(--form-check-input-indeterminate-bg-image);
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.625;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.625;
  cursor: default;
}

.form-check:not(.form-switch) .form-check-input {
  cursor: pointer;
  margin-top: calc((var(--line-height-base) * var(--font-size-base) - 1rem) * 0.5);
}

.form-check-label[for] {
  cursor: pointer;
}

.form-switch {
  padding-left: 0;
}
.form-switch .form-check-input {
  float: none;
  width: var(--form-switch-width);
  height: var(--form-switch-height);
  margin-top: 0;
  vertical-align: bottom;
  border-radius: var(--form-switch-border-radius);
  background-color: var(--form-switch-bg);
  background-image: var(--form-switch-bg-image);
  background-position: left center;
  cursor: pointer;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: var(--form-switch-focus-bg-image);
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: var(--form-switch-checked-bg-image);
}
.form-switch .form-check-input:checked[type=checkbox] {
  background-color: var(--form-switch-checked-bg);
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
}

.inline-checkboxes {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.block-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control-with-icons,
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control-with-icons > .form-control:focus,
.input-group > .form-control-with-icons > .form-select:focus,
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group:not(.has-validation) > .form-control-with-icons:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-control-with-icons:not(:last-child) > .form-select,
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control-with-icons:not(:first-child) > .form-control,
.input-group > .form-control-with-icons:not(:first-child) > .form-select,
.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--form-feedback-valid);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--form-feedback-valid);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--form-feedback-valid);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--form-feedback-valid);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--form-feedback-valid);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--form-feedback-valid);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--form-feedback-valid);
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--form-feedback-invalid);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--form-feedback-invalid);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--form-feedback-invalid);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--form-feedback-invalid);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--form-feedback-invalid);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--form-feedback-invalid);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--form-feedback-invalid);
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.form-control.is-warning {
  border-color: var(--form-feedback-warning);
}
.form-control.is-warning:focus {
  border-color: var(--form-feedback-warning);
}

.form-select.is-warning {
  border-color: var(--form-feedback-warning);
}
.form-select.is-warning:focus {
  border-color: var(--form-feedback-warning);
}

.form-check-input.is-warning {
  border-color: var(--form-feedback-warning);
}
.form-check-input.is-warning:checked {
  background-color: var(--form-feedback-warning);
}
.form-check-input.is-warning ~ .form-check-label {
  color: var(--form-feedback-warning);
}

.input-group .form-control.is-warning:focus,
.input-group .form-select.is-warning:focus {
  z-index: 3;
}

.row {
  --gutter-x: 1.5rem;
  --gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gutter-y));
  margin-right: calc(-0.5 * var(--gutter-x));
  margin-left: calc(-0.5 * var(--gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--gutter-x) * 0.5);
  padding-left: calc(var(--gutter-x) * 0.5);
  margin-top: var(--gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --gutter-x: 0;
}

.g-0,
.gy-0 {
  --gutter-y: 0;
}

.g-1,
.gx-1 {
  --gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --gutter-y: 1.5rem;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 700;
}

h3 {
  font-weight: 500;
}

h4 {
  font-weight: 600;
}

h5 {
  font-weight: 500;
}

h6 {
  font-weight: 500;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 1.375rem;
  pointer-events: none;
}

.modal-dialog-scrollable {
  height: calc(100% - 2.75rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 2.75rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--modal-color);
  pointer-events: auto;
  background-color: var(--modal-bg);
  background-clip: padding-box;
  border: 1px solid var(--modal-border);
  border-radius: var(--modal-border-radius);
  box-shadow: var(--modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: var(--modal-backdrop-bg);
}

.cdk-overlay-dark-backdrop {
  background-color: var(--modal-backdrop-bg);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 2.25rem;
  border-top-left-radius: calc(var(--modal-border-radius) - 1px);
  border-top-right-radius: calc(var(--modal-border-radius) - 1px);
}
.modal-header .close-btn {
  min-height: 0;
  margin: -0.5rem -0.5rem -0.5rem auto;
  padding: 0.5rem;
  border: 0;
  color: var(--modal-close-btn-color);
}
.modal-header .close-btn .heroicon {
  width: 1.5rem;
}

.modal-title {
  margin-bottom: 0;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 2.25rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 2rem;
  border-bottom-right-radius: calc(var(--modal-border-radius) - 1px);
  border-bottom-left-radius: calc(var(--modal-border-radius) - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-dialog {
  max-width: 494px;
  margin: 6.5625rem auto;
}

.modal-dialog-scrollable {
  height: calc(100% - 13.125rem);
}

.modal-dialog-centered {
  min-height: calc(100% - 13.125rem);
}

.modal-sm {
  max-width: 300px;
}

.modal-lg,
.modal-xl {
  max-width: 800px;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--nav-tabs-link-padding-y) var(--nav-tabs-link-padding-x);
  font-weight: 600;
  color: var(--nav-link-color);
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--nav-link-hover-color);
}
.nav-link.disabled {
  color: var(--nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  gap: var(--nav-tabs-items-gap);
}
.nav-tabs .nav-link {
  margin-bottom: calc(var(--nav-tabs-link-border-width) * -1);
  background: none;
  border-bottom: var(--nav-tabs-link-border-width) solid var(--nav-tabs-border);
  font-size: var(--h5-font-size);
  font-weight: 600;
  text-transform: uppercase;
}
.nav-tabs .nav-link:hover {
  border-color: var(--nav-tabs-link-hover-border);
  isolation: isolate;
}
.nav-tabs .nav-link:focus {
  border-color: var(--nav-tabs-link-focus-border);
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: var(--nav-link-disabled-color);
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: var(--nav-tabs-link-active-border);
  background-color: var(--nav-tabs-link-active-bg);
  color: var(--nav-tabs-link-active-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(var(--nav-tabs-link-border-width) * -1);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.progress {
  --progress-height: 0.25rem;
  position: relative;
  height: var(--progress-height);
  display: block;
  width: 100%;
  background-color: var(--progress-bg);
  background-clip: padding-box;
  overflow: hidden;
}
.progress .determinate {
  position: absolute;
  background-color: inherit;
  top: 0;
  bottom: 0;
  background-color: var(--progress-determinate);
  transition: width 0.3s linear;
}
.progress .indeterminate {
  background-color: var(--progress-indeterminate);
}
.progress .indeterminate::before {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}
.progress .indeterminate::after {
  content: "";
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}
@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
.table {
  width: 100%;
  vertical-align: top;
  border-color: var(--table-border);
  color: var(--table-color);
}
.table > :not(caption) > * > *.minimal-column {
  width: 1%;
  white-space: nowrap;
}
.table > :not(caption, thead) > * > * {
  padding: var(--table-cell-padding-y) var(--table-cell-padding-x);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
  color: var(--table-thead-color);
}
.table > thead > * > * {
  padding: var(--table-thead-cell-padding-y) var(--table-thead-cell-padding-x);
  border-bottom: 1px solid var(--table-border) !important;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-row-bordered > thead > * > * {
  border-bottom-width: 1px;
}
.table-row-bordered > tbody > *:not(:first-child) > * {
  border-top-width: 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-condensed {
  --table-cell-padding-y: var(--table-condensed-cell-padding-y);
  --table-cell-padding-x: var(--table-condensed-cell-padding-x);
}

.table-hover-accent-row > tbody > tr:hover {
  background-color: var(--table-accent-bg);
  color: var(--table-accent-color);
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: var(--table-accent-bg);
  color: var(--table-accent-color);
}

.table > tbody > th.accent,
.table > tbody > td.accent,
.table > tbody > tr.accent {
  background-color: var(--table-accent-bg);
  color: var(--table-accent-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.toasts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--toasts-gap);
  position: fixed;
  right: var(--toasts-indent-x);
  bottom: var(--toasts-indent-x);
  z-index: 99999;
}

.toast {
  --toast-padding-x: 1rem;
  --toast-padding-y: 1rem;
  --toast-gap-x: var(--toast-padding-x);
  --toast-gap-y: calc(var(--toast-padding-y) * 0.5);
  --toast-border-radius: 0.375rem;
  --toast-autoclose-progress-height: 0.25rem;
  --toast-bg: var(--toast-default-bg);
  --toast-status-color: var(--toast-default-status-color);
  --toast-heading-color: var(--toast-default-heading-color);
  --toast-content-color: var(--toast-default-content-color);
  --toast-content-link-color: var(--toast-default-content-link-color);
  --toast-close-btn-color: var(--toast-default-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-default-color-scheme);
  position: relative;
  display: inline-grid;
  grid-template-columns: 1.25rem 1fr 1.25rem;
  gap: var(--toast-gap-y) var(--toast-gap-x);
  min-width: 16rem;
  padding: var(--toast-padding-y) var(--toast-padding-x);
  border-radius: var(--toast-border-radius);
  overflow: hidden;
  background-color: var(--toast-bg);
  box-shadow: var(--toast-box-shadow);
  color-scheme: var(--toast-color-scheme);
}
.toast_autoclose {
  cursor: pointer;
}
.toast-status {
  grid-column: 1/2;
  grid-row: 1/2;
  font-size: 0;
  color: var(--toast-status-color);
}
.toast-status .heroicon {
  width: 1.25rem;
}
.toast-heading {
  grid-column: 2/4;
  grid-row: 1/2;
  line-height: 1.11111;
  font-size: 1.125rem;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--toast-heading-color);
}
.toast_with-close-btn .toast-heading {
  grid-column: 2/3;
}
.toast-close {
  grid-column: 3/4;
  grid-row: 1/2;
  font-size: 0;
}
.toast-close .heroicon {
  width: 1.25rem;
}
.toast-close-btn {
  padding: var(--toast-padding-y) var(--toast-padding-x);
  margin: calc(var(--toast-padding-y) * -1) calc(var(--toast-padding-x) * -1);
  border: 0;
  background: none;
  color: var(--toast-close-btn-color);
}
.toast-content {
  grid-column: 2/4;
  grid-row: 2/3;
  font-size: 0.75rem;
  color: var(--toast-content-color);
}
.toast-content a {
  font-weight: 600;
  color: var(--toast-content-link-color);
}
.toast_with-close-btn .toast-content {
  grid-column: 2/3;
}
.toast-autoclose-progress {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--toast-autoclose-progress-height);
  background-color: var(--toast-autoclose-progress-bg);
}

.toast_info {
  --toast-bg: var(--toast-info-bg);
  --toast-status-color: var(--toast-info-status-color);
  --toast-heading-color: var(--toast-info-heading-color);
  --toast-content-color: var(--toast-info-content-color);
  --toast-content-link-color: var(--toast-info-content-link-color);
  --toast-close-btn-color: var(--toast-info-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-info-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-info-color-scheme);
}

.toast_success {
  --toast-bg: var(--toast-success-bg);
  --toast-status-color: var(--toast-success-status-color);
  --toast-heading-color: var(--toast-success-heading-color);
  --toast-content-color: var(--toast-success-content-color);
  --toast-content-link-color: var(--toast-success-content-link-color);
  --toast-close-btn-color: var(--toast-success-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-success-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-success-color-scheme);
}

.toast_warning {
  --toast-bg: var(--toast-warning-bg);
  --toast-status-color: var(--toast-warning-status-color);
  --toast-heading-color: var(--toast-warning-heading-color);
  --toast-content-color: var(--toast-warning-content-color);
  --toast-content-link-color: var(--toast-warning-content-link-color);
  --toast-close-btn-color: var(--toast-warning-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-warning-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-warning-color-scheme);
}

.toast_error {
  --toast-bg: var(--toast-error-bg);
  --toast-status-color: var(--toast-error-status-color);
  --toast-heading-color: var(--toast-error-heading-color);
  --toast-content-color: var(--toast-error-content-color);
  --toast-content-link-color: var(--toast-error-content-link-color);
  --toast-close-btn-color: var(--toast-error-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-error-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-error-color-scheme);
}

.tooltip {
  --tooltip-border-width: 0;
  --tooltip-border-radius: var(--border-radius-sm);
  --tooltip-spacer-y: 0.625rem;
  --tooltip-spacer-x: 0.625rem;
  position: relative;
  padding: var(--tooltip-spacer-y) var(--tooltip-spacer-x);
  background-color: var(--tooltip-bg);
  border: var(--tooltip-border-width) solid var(--tooltip-border);
  border-radius: var(--tooltip-border-radius);
  filter: drop-shadow(var(--tooltip-box-shadow));
  white-space: nowrap;
  font-size: 0.75rem;
  color: var(--tooltip-color);
}
.tooltip::before {
  content: "";
  pointer-events: none;
  position: absolute;
  transform-origin: center center;
  z-index: 1;
  display: none;
  width: 0.6875rem;
  height: 0.6875rem;
  border-top: var(--tooltip-border-width) solid var(--tooltip-border);
  border-left: var(--tooltip-border-width) solid var(--tooltip-border);
  background-color: var(--tooltip-bg);
}

.center-top .tooltip::before,
.center-bottom .tooltip::before,
.start-center .tooltip::before,
.end-center .tooltip::before {
  display: block;
}

.center-top .tooltip::before,
.center-bottom .tooltip::before {
  left: 50%;
}

.center-top .tooltip::before {
  top: 0;
  transform: translate3d(-50%, -33%, 0) rotate(0.125turn);
}

.center-bottom .tooltip::before {
  top: 100%;
  transform: translate3d(-50%, -66%, 0) rotate(0.125turn);
}

.start-center .tooltip::before,
.end-center .tooltip::before {
  top: 50%;
}

.start-center .tooltip::before {
  left: 0;
  transform: translate3d(-33%, -50%, 0) rotate(0.125turn);
}

.end-center .tooltip::before {
  right: 0;
  transform: translate3d(33%, -50%, 0) rotate(0.125turn);
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-success {
  color: var(--success) !important;
}

.text-danger {
  color: var(--danger) !important;
}

.text-warning {
  color: var(--warning) !important;
}

.text-info {
  color: var(--info) !important;
}

.text-body {
  color: var(--body-color) !important;
}

.text-muted {
  color: var(--text-muted) !important;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.bg-success {
  background-color: var(--success) !important;
}

.bg-danger {
  background-color: var(--danger) !important;
}

.bg-warning {
  background-color: var(--warning) !important;
}

.bg-info {
  background-color: var(--info) !important;
}

.bg-body {
  background-color: var(--body-bg) !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.visibility-visible {
  visibility: visible !important;
}

.visibility-invisible {
  visibility: hidden !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.layout-logo {
  width: var(--layout-logo-width);
  height: var(--layout-logo-height);
}

.layout-logo-link {
  text-decoration: none;
}

.text-link {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: var(--h5-font-size);
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
.text-link .heroicon {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

button.text-link {
  padding: 0;
  background-color: transparent;
  border: 0;
}

.icon-btn-in-table {
  --btn-color: var(--icon-btn-in-table-color);
  --btn-hover-color: var(--icon-btn-in-table-color);
  position: relative;
  top: -0.0625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin: -2rem -0.9375rem;
  border: 0;
}
.icon-btn-in-table .svg-icon,
.icon-btn-in-table .heroicon {
  width: 1.5rem;
}

.huge-icon {
  display: inline-flex;
  width: var(--huge-icon-size);
  height: var(--huge-icon-size);
  border: var(--huge-icon-border-width) solid;
  margin-bottom: 2.75rem;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.huge-icon_close {
  background-color: var(--huge-icon-close-bg);
  border-color: var(--huge-icon-close-border);
  background-image: var(--huge-icon-close-bg-image);
}
.huge-icon_check {
  background-color: var(--huge-icon-check-bg);
  border-color: var(--huge-icon-check-border);
  background-image: var(--huge-icon-check-bg-image);
}
.huge-icon_plus {
  background-color: var(--huge-icon-plus-bg);
  border-color: var(--huge-icon-plus-border);
  background-image: var(--huge-icon-plus-bg-image);
}

google-chart svg > rect {
  fill: var(--chart-bg);
}
google-chart svg > g > g:nth-of-type(1) > g:nth-of-type(1) rect {
  fill: var(--chart-vAxis-gridline-color);
}
google-chart svg > g > g:nth-of-type(3) text {
  fill: var(--chart-color);
}
google-chart.google-chart_line-chart svg > g > g:nth-of-type(1) > g:nth-of-type(2) rect {
  fill: var(--chart-vAxis-baseline-color);
}
google-chart.google-chart_column-chart svg > g > g:nth-of-type(1) > g:nth-of-type(3) rect {
  fill: var(--chart-vAxis-baseline-color);
}

body .google-visualization-tooltip {
  --google-visualization-tooltip-gap: 0.5rem;
  position: absolute;
  z-index: 10000;
  padding: 0;
  border: 0;
  border-radius: var(--border-radius);
  background-color: var(--inverted-bg);
  box-shadow: none;
  color: var(--inverted-color);
  font-size: 0.75rem;
}
body .google-visualization-tooltip-inner {
  padding: var(--google-visualization-tooltip-gap);
}
body .google-visualization-tooltip-inner .accent {
  font-size: 1.125rem;
  font-weight: 700;
}

dl {
  padding-left: 2rem;
  list-style: decimal;
  counter-reset: item;
}

dt {
  display: list-item;
  counter-increment: item;
}

dd {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
dd dt {
  counter-increment: item;
}
dd dt::marker {
  content: counters(item, ".") ". ";
}
dd ol {
  counter-reset: item;
}
dd ol > li {
  counter-increment: item;
}
dd ol > li::marker {
  content: counters(item, ".") ". ";
}

.cdk-overlay-pane-fullheight {
  height: 100%;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #12b5a7;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #06b5d4;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #12b5a7;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #12b5a7;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #06b5d4;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #06b5d4;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #06b5d4;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #06b5d4;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #12b5a7;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(18, 181, 167, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(18, 181, 167, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(18, 181, 167, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #12b5a7;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(18, 181, 167, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: black;
  --mat-datepicker-calendar-date-selected-state-background-color: #06b5d4;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(6, 181, 212, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: black;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(6, 181, 212, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(6, 181, 212, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(6, 181, 212, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #06b5d4;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

:root {
  --primary-h: 175;
  --primary-s: 82%;
  --primary-l: 39%;
  --success-rgb: 5, 150, 105;
  --danger-rgb: 230, 34, 34;
  --warning-rgb: 217, 119, 6;
  --info-rgb: 2, 132, 199;
  --primary: hsl(var(--primary-h) var(--primary-s) var(--primary-l));
  --success: rgb(var(--success-rgb));
  --danger: rgb(var(--danger-rgb));
  --warning: rgb(var(--warning-rgb));
  --info: rgb(var(--info-rgb));
  --font-sans-serif: "Inter", sans-serif;
  --font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, monospace;
  --font-family-base: var(--font-sans-serif);
  --font-family-code: var(--font-monospace);
  --font-size-root: 16px;
  --font-size-base: 0.875rem;
  --line-height-base: 1.42;
  --body-font-family: var(--font-family-base);
  --body-color: var(--text-color-2);
  --body-bg: #F2F2F2;
  --code-color: #D63384;
  --pre-color: var(--body-color);
  --border-radius: 0.375rem;
  --border-radius-sm: 0.1875rem;
  --border-radius-lg: 0.5rem;
  --inverted-color: #FFFFFF;
  --inverted-bg: #374151;
  --kbd-color: var(--inverted-color);
  --kbd-bg: var(--inverted-bg);
  --kbd-border-radius: var(--border-radius);
  --link-color: #06B6D4;
  --link-hover-color: #06B6D4;
  --text-color-1: #000000;
  --text-color-2: #374151;
  --text-color-3: #6B7280;
  --text-muted: #ACACAC;
  --text-danger: var(--danger);
  --border-color: #E5E7EB;
  --powered-by-color: var(--text-color-3);
  --mark-bg: #FCF8E3;
  --layout-logo-width: 15.916875rem;
  --layout-logo-height: 2.25rem;
}

@supports (font-variation-settings: normal) {
  :root {
    --font-sans-serif: "Inter var", sans-serif;
  }
}
:root {
  --elevation-level-0: none;
  --elevation-level-1: 0 0 3px rgba(0, 0, 0, 0.15);
  --elevation-level-2: 0 3px 6px rgba(0, 0, 0, 0.15);
  --elevation-level-3: 0 5px 12px rgba(0, 0, 0, 0.05);
  --elevation-level-4: 0 10px 12px rgba(0, 0, 0, 0.1);
}

:root {
  --alert-space-x: 1rem;
  --alert-space-y: 1rem;
  --alert-gap: 1rem;
  --alert-header-gap: 0.5rem;
  --alert-heading-line-height: 1.16667;
  --alert-heading-font-size: 1.125rem;
  --alert-heading-font-weight: 600;
  --alert-border-radius: var(--border-radius);
  --alert-icon-size: 1.25rem;
  --alert-bordered-border-width: 1px;
  --alert-embedded-unindented-header-gap: 1rem;
  --alert-neutral-bg-color: #F5F6F8;
  --alert-neutral-border-color: #F5F6F8;
  --alert-neutral-text-color: var(--text-color-3);
  --alert-neutral-heading-color: var(--text-color-2);
  --alert-neutral-icon-color: #F2A50C;
  --alert-success-bg-color: #E4FEE4;
  --alert-success-border-color: #ACD1AC;
  --alert-success-text-color: #084E23;
  --alert-success-heading-color: #084E23;
  --alert-success-icon-color: #219F51;
  --alert-danger-bg-color: #FEF2F2;
  --alert-danger-border-color: #CCC2C2;
  --alert-danger-text-color: #B31B1B;
  --alert-danger-heading-color: #B31B1B;
  --alert-danger-icon-color: #E62222;
  --alert-warning-bg-color: #FFFBE6;
  --alert-warning-border-color: #E6E2CF;
  --alert-warning-text-color: #B35009;
  --alert-warning-heading-color: #B35009;
  --alert-warning-icon-color: #F2A50C;
  --alert-info-bg-color: #E6F3FF;
  --alert-info-border-color: #BDCBF8;
  --alert-info-text-color: #1B41B3;
  --alert-info-heading-color: #1B41B3;
  --alert-info-icon-color: #3C88F2;
}

:root {
  --applied-filter-badge-bg: #EEEEEE;
  --applied-filter-badge-color: var(--text-color-1);
  --applied-filter-badge-remove-icon-color: var(--text-color-3);
}

:root {
  --badge-padding-x: 0.7272em;
  --badge-padding-y: 0.2727em;
  --badge-line-height: 1.27273;
  --badge-border-radius: 99999px;
  --badge-success-bg-color: #12B3A5;
  --badge-success-text-color: #FFFFFF;
  --badge-danger-bg-color: #E62222;
  --badge-danger-text-color: #FFFFFF;
  --badge-warning-bg-color: #FBBF24;
  --badge-warning-text-color: #303030;
  --badge-pending-bg-color: #D8D8D8;
  --badge-pending-text-color: #303030;
  --badge-neutral-bg: #8194A7;
  --badge-neutral-color: #FFFFFF;
}

:root {
  --btn-bg: transparent;
  --btn-border: transparent;
  --btn-border-radius: var(--border-radius);
  --btn-color: var(--body-bg);
  --btn-box-shadow: none;
  --btn-font-weight: 500;
  --btn-hover-bg: transparent;
  --btn-hover-border: transparent;
  --btn-hover-color: var(--body-bg);
  --btn-active-bg: transparent;
  --btn-active-border: transparent;
  --btn-active-color: var(--body-bg);
  --btn-active-box-shadow: none;
  --btn-focus-box-shadow: 0 0 0 4px hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.25);
  --btn-disabled-bg: #F3F4F6;
  --btn-disabled-border: #E5E7EB;
  --btn-disabled-color: #9CA3AF;
}

.btn-primary {
  --btn-primary-bg: var(--primary);
  --btn-primary-border: var(--primary);
  --btn-primary-color: #FFFFFF;
  --btn-primary-font-weight: 700;
  --btn-primary-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  --btn-primary-hover-bg: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 8%), 100%));
  --btn-primary-hover-border: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 8%), 100%));
  --btn-primary-hover-color: #FFFFFF;
  --btn-primary-active-bg: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 8%), 100%));
  --btn-primary-active-border: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 8%), 100%));
  --btn-primary-active-color: #FFFFFF;
  --btn-primary-active-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.125);
  --btn-primary-focus-box-shadow: 0 0 0 4px hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.25);
  --btn-primary-disabled-bg: hsl(0 0% 70%);
  --btn-primary-disabled-border: hsl(0 0% 70%);
  --btn-primary-disabled-color: #4B5563;
}

.btn-danger {
  --btn-danger-bg: #E62222;
  --btn-danger-border: #E62222;
  --btn-danger-color: #FFFFFF;
  --btn-danger-font-weight: 500;
  --btn-danger-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  --btn-danger-hover-bg: #B31B1B;
  --btn-danger-hover-border: #B31B1B;
  --btn-danger-hover-color: #FFFFFF;
  --btn-danger-active-bg: #B31B1B;
  --btn-danger-active-border: #B31B1B;
  --btn-danger-active-color: #FFFFFF;
  --btn-danger-active-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.125);
  --btn-danger-focus-box-shadow: 0 0 0 4px rgba(var(--danger-rgb), 0.25);
  --btn-danger-disabled-bg: #F3F4F6;
  --btn-danger-disabled-border: #E5E7EB;
  --btn-danger-disabled-color: #9CA3AF;
}

.btn-danger-outline {
  --btn-danger-outline-bg: #FFFFFF;
  --btn-danger-outline-border: #E62222;
  --btn-danger-outline-color: #E62222;
  --btn-danger-outline-font-weight: 500;
  --btn-danger-outline-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  --btn-danger-outline-hover-bg: #FFFFFF;
  --btn-danger-outline-hover-border: #B31B1B;
  --btn-danger-outline-hover-color: #B31B1B;
  --btn-danger-outline-active-bg: #FFFFFF;
  --btn-danger-outline-active-border: #B31B1B;
  --btn-danger-outline-active-color: #B31B1B;
  --btn-danger-outline-active-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.125);
  --btn-danger-outline-focus-box-shadow: 0 0 0 4px rgba(var(--danger-rgb), 0.25);
  --btn-danger-outline-disabled-bg: #F3F4F6;
  --btn-danger-outline-disabled-border: #E5E7EB;
  --btn-danger-outline-disabled-color: #9CA3AF;
}

.btn-outline {
  --btn-outline-bg: #FFFFFF;
  --btn-outline-border: #E5E7EB;
  --btn-outline-color: #4B5563;
  --btn-outline-font-weight: 500;
  --btn-outline-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  --btn-outline-hover-bg: #F9FAFB;
  --btn-outline-hover-border: #E5E7EB;
  --btn-outline-hover-color: #4B5563;
  --btn-outline-active-bg: #F9FAFB;
  --btn-outline-active-border: #E5E7EB;
  --btn-outline-active-color: #4B5563;
  --btn-outline-active-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.125);
  --btn-outline-focus-box-shadow: 0 0 0 4px hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.25);
  --btn-outline-disabled-bg: #F3F4F6;
  --btn-outline-disabled-border: #E5E7EB;
  --btn-outline-disabled-color: #9CA3AF;
}

.btn-outline2 {
  --btn-outline2-bg: #FFFFFF;
  --btn-outline2-border: #4B5563;
  --btn-outline2-color: #4B5563;
  --btn-outline2-font-weight: 500;
  --btn-outline2-box-shadow: none;
  --btn-outline2-hover-bg: #F9FAFB;
  --btn-outline2-hover-border: #4B5563;
  --btn-outline2-hover-color: #4B5563;
  --btn-outline2-active-bg: #4B5563;
  --btn-outline2-active-border: #4B5563;
  --btn-outline2-active-color: #FFFFFF;
  --btn-outline2-active-box-shadow: none;
  --btn-outline2-focus-box-shadow: 0 0 0 4px hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.25);
  --btn-outline2-disabled-bg: #F3F4F6;
  --btn-outline2-disabled-border: #9CA3AF;
  --btn-outline2-disabled-color: #9CA3AF;
}

.btn-link {
  --btn-link-bg: transparent;
  --btn-link-border: transparent;
  --btn-link-color: var(--link-color);
  --btn-link-font-weight: 400;
  --btn-link-box-shadow: none;
  --btn-link-hover-bg: transparent;
  --btn-link-hover-border: transparent;
  --btn-link-hover-color: var(--link-hover-color);
  --btn-link-active-bg: transparent;
  --btn-link-active-border: transparent;
  --btn-link-active-color: var(--link-hover-color);
  --btn-link-active-box-shadow: none;
  --btn-link-focus-box-shadow: none;
  --btn-link-disabled-bg: transparent;
  --btn-link-disabled-border: transparent;
  --btn-link-disabled-color: #BBBBBB;
}

:root {
  --card-bg-color: #FFFFFF;
  --card-border-color: transparent;
  --card-text-color: var(--text-color-3);
  --card-border-width: 0px;
  --card-border-radius: var(--border-radius);
  --bicolumn-card-description-bg-color: #F9FAFB;
  --bicolumn-card-description-width: 22.125rem;
  --bicolumn-card-description-padding-x: 2rem;
  --bicolumn-card-description-padding-y: 2rem;
  --bicolumn-card-content-padding-x: 2rem;
  --bicolumn-card-content-padding-y: 2rem;
  --bicolumn-card-content-actions-gap: 1.5rem;
  --card-spacer-y: 1.5rem;
  --card-spacer-x: 1.5rem;
  --card-box-shadow: var(--elevation-level-1);
  --card-header-bg-color: rgba(0, 0, 0, 0.03);
  --card-header-text-color: var(--body-color);
  --card-tabs-bg-color: rgba(0, 0, 0, 0.03);
  --card-tabs-link-color: var(--body-color);
  --card-tabs-link-active-color: var(--card-tabs-link-color);
  --card-footer-bg-color: var(--card-header-bg-color);
  --card-footer-text-color: var(--card-text-color);
  --empty-card-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 150'><path fill='%23f6f6f6' d='M155 75a75 75 0 0 1-75 75A75 75 0 0 1 5 75 75 75 0 0 1 80 0a75 75 0 0 1 75 75Z'/><path fill='%23fff' d='M6 22h148c3.324 0 6 2.676 6 6v98c0 3.324-2.676 6-6 6H6c-3.324 0-6-2.676-6-6V28c0-3.324 2.676-6 6-6z'/><path fill='none' stroke='%23707070' stroke-width='2' d='M90.59 92.681c-3.572 7.157-13.1 6.15-15.761 4.669'/><path fill='%2306b6d4' d='M65.986 69.781998a4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4 4 4 0 0 1 4 4zM98.846001 67.695a4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4 4 4 0 0 1 4 4z'/><path fill='%23c1c1c1' d='M6 22c-3.3101588 0-6 2.689841-6 6v98c0 3.31016 2.6898412 6 6 6h148c3.31016 0 6-2.68984 6-6V28c0-3.310159-2.68984-6-6-6H6zm0 1.599609h148c2.45144 0 4.40039 1.948956 4.40039 4.400391v11.699219H1.5996094V28c0-2.451435 1.9489554-4.400391 4.4003906-4.400391zM1.5996094 41.300781H158.40039V126c0 2.45144-1.94895 4.40039-4.40039 4.40039H6c-2.4514352 0-4.4003906-1.94895-4.4003906-4.40039V41.300781z' color='%23000' /></svg>");
}

:root {
  --connections-item-border-color: var(--border-color);
  --connections-image-border-color: rgba(112, 112, 112, 0.2);
  --new-connection-filter-input-border: #E0E0E0;
  --new-connection-filter-input-placeholder-color: var(--text-color-3);
  --add-new-connection-btn-bg: #FFFFFF;
  --add-new-connection-btn-color: var(--primary);
  --add-new-connection-btn-border: var(--primary);
  --add-new-connection-btn-hover-bg: #FFFFFF;
  --add-new-connection-btn-hover-color: #0D9488;
  --add-new-connection-btn-hover-border: #0D9488;
  --add-new-connection-btn-disabled-bg: #F3F4F6;
  --add-new-connection-btn-disabled-color: #9CA3AF;
  --add-new-connection-btn-disabled-border: #9CA3AF;
  --connection-active-icon-color: var(--primary);
  --connection-broken-text-color: #B31B1B;
  --connection-broken-icon-color: #E62222;
  --connection-incorrectly-configured-text-color: #B31B1B;
  --connection-incorrectly-configured-icon-color: #E62222;
  --connection-test-environment-text-color: #B35009;
  --connection-test-environment-icon-color: #F2A50C;
}

:root {
  --dashboard-logo-h-alignment: flex-start;
  --dashboard-logo-h-indent: 1.125rem;
  --dashboard-logo-v-alignment: center;
  --dashboard-logo-v-indent: 0;
  --dashboard-logo-width: 12.38rem;
  --dashboard-logo-height: 1.75rem;
  --dashboard-header-height: 4.3125rem;
  --dashboard-header-bg: #FFFFFF;
  --dashboard-header-border: transparent;
  --dashboard-header-border-width: 0;
  --dashboard-header-box-shadow: var(--elevation-level-1);
  --dashboard-menu-link-color: var(--text-color-3);
  --dashboard-menu-link-hover-color: #4B5563;
  --dashboard-menu-link-active-color: #111827;
  --dashboard-header-company-chooser-icon-color: #ACACAC;
  --dashboard-header-company-chooser-dropdown-item-active-border: var(--primary);
  --dashboard-header-company-chooser-dropdown-item-hover-bg: #FBFBFB;
  --dashboard-header-form-select-indicator-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'><path fill='%239CA3AF' d='M6.00011 8L0.342773 2.34267L2.22944 0.457336L6.00011 4.22934L9.77077 0.457336L11.6574 2.34267L6.00011 8Z'/></svg>");
  --dashboard-user-profile-btn-bg: #9CA3AF;
  --dashboard-user-profile-btn-color: #FFFFFF;
  --dashboard-user-profile-btn-hover-bg: #798293;
  --dashboard-user-profile-btn-hover-color: var(--dashboard-user-profile-btn-color);
  --dashboard-user-profile-btn-active-bg: #5b6371;
  --dashboard-user-profile-btn-active-color: var(--dashboard-user-profile-btn-color);
  --dashboard-notifications-btn-bg: #F3F4F6;
  --dashboard-notifications-btn-color: #9CA3AF;
  --dashboard-notifications-btn-hover-bg: var(--dashboard-notifications-btn-bg);
  --dashboard-notifications-btn-hover-color: #798293;
  --dashboard-notifications-btn-active-bg: var(--dashboard-notifications-btn-bg);
  --dashboard-notifications-btn-active-color: #5b6371;
  --dashboard-aside-expanded-width: 21rem;
  --dashboard-aside-collapsed-width: 4.5rem;
  --dashboard-aside-padding: 2.5rem 0.625rem 0 0.625rem;
  --dashboard-aside-bg: var(--dashboard-header-bg);
  --dashboard-aside-border: var(--border-color);
  --dashboard-aside-nav-item-font-size: 1.125rem;
  --dashboard-aside-nav-item-icon-size: 2rem;
  --dashboard-aside-nav-item-bg: transparent;
  --dashboard-aside-nav-item-color: #4B5563;
  --dashboard-aside-nav-item-border-radius: var(--border-radius);
  --dashboard-aside-nav-item-icon-color: #9CA3AF;
  --dashboard-aside-nav-item-active-bg: #F3F4F6;
  --dashboard-aside-nav-item-active-color: #111827;
  --dashboard-aside-nav-item-active-icon-color: var(--text-color-3);
  --dashboard-aside-nav-divider-color: #F3F3F3;
  --dashboard-aside-toggle-mode-btn-color: #CECECE;
  --dashboard-gap: 3.4375rem;
  --dashboard-no-companies-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 87.184'><path fill='%2322D3EE' d='m78.038 40.844c-0.8317 0.1027-1.6788 0.1556-2.5382 0.1556-10.36 0-18.925-7.6848-20.306-17.665h-44.553c-1.959 0-3.5472 1.5882-3.5472 3.5472v17.736h70.944zm7.0943-2.2439c6.4664-3.4483 10.868-10.26 10.868-18.1 0-11.322-9.1782-20.5-20.5-20.5-5.0072 0-9.5952 1.7952-13.155 4.7771-1.2985-1.6589-3.3196-2.7251-5.5896-2.7251h-28.378c-3.9181 0-7.0943 3.1763-7.0943 7.0944v7.0944h-10.642c-5.8771 0-10.642 4.7644-10.642 10.642v60.302h85.132zm-28.377-26.412v-3.0417h-28.378v7.0944h27.065c0.2979-1.4098 0.7407-2.7662 1.3121-4.0527zm2.5372 8.312c0 8.9513 7.2564 16.208 16.208 16.208 8.9512 0 16.208-7.2564 16.208-16.208s-7.2564-16.208-16.208-16.208c-8.9513 0-16.208 7.2564-16.208 16.208zm19.172-2.532c0 0.4447-0.1534 0.9047-0.46 1.38-0.3067 0.46-0.7744 0.851-1.403 1.173-0.6134 0.3067-1.38 0.46-2.3 0.46h-0.713l0.46 4.094h2.369l0.138-2.208c0.828-0.1073 1.6176-0.3757 2.369-0.805 0.7513-0.4293 1.357-0.989 1.817-1.679 0.4753-0.69 0.713-1.495 0.713-2.415 0-0.7667-0.1534-1.449-0.46-2.047-0.2914-0.6133-0.7054-1.1347-1.242-1.564-0.5214-0.4447-1.1194-0.782-1.794-1.012-0.6594-0.23-1.357-0.345-2.093-0.345-0.9047 0-1.7097 0.115-2.415 0.345-0.7054 0.23-1.334 0.5597-1.886 0.989s-1.0734 0.9507-1.564 1.564l2.093 1.633c0.414-0.6287 0.9276-1.1117 1.541-1.449 0.6133-0.3527 1.2803-0.529 2.001-0.529 0.5826 0 1.081 0.0997 1.495 0.299 0.4293 0.184 0.759 0.4523 0.989 0.805s0.345 0.7897 0.345 1.311zm-5.083 10.281c0 0.46 0.1763 0.8587 0.529 1.196 0.368 0.3373 0.8203 0.506 1.357 0.506 0.552 0 1.0043-0.1687 1.357-0.506 0.3526-0.3373 0.529-0.736 0.529-1.196s-0.1764-0.8587-0.529-1.196c-0.3527-0.3373-0.805-0.506-1.357-0.506-0.5367 0-0.989 0.1687-1.357 0.506-0.3527 0.3373-0.529 0.736-0.529 1.196zm-66.286 51.841v-28.377h31.925v7.0943h7.0943v-7.0943h31.925v28.377z' clip-rule='evenodd' fill-rule='evenodd'/></svg>");
  --dashboard-widgets-gap: 1.8751rem;
  --widget-bg: var(--card-bg-color);
  --widget-border: var(--card-border-color);
  --widget-color: var(--card-text-color);
  --widget-border-width: var(--card-border-width);
  --widget-border-radius: var(--card-border-radius);
  --widget-spacer-y: var(--card-spacer-y);
  --widget-spacer-x: var(--card-spacer-x);
  --widget-box-shadow: var(--card-box-shadow);
  --chart-bg: var(--widget-bg);
  --chart-color: var(--widget-color);
  --chart-vAxis-gridline-color: #F9F7F9;
  --chart-vAxis-baseline-color: #E5E7EB;
}

:root {
  --dropdown-bg: #FFFFFF;
  --dropdown-border: transparent;
  --dropdown-color: var(--body-color);
  --dropdown-divider-color: var(--table-border);
  --dropdown-box-shadow: var(--elevation-level-2);
  --dropdown-item-bg: trasparent;
  --dropdown-item-color: var(--dropdown-color);
  --dropdown-item-hover-bg: #EEEEEE;
  --dropdown-item-hover-color: var(--dropdown-color);
  --dropdown-item-active-bg: #E3E3E3;
  --dropdown-item-active-color: var(--dropdown-color);
}

.flow-steps {
  --flow-steps-completed-label-color: #6B7280;
  --flow-steps-completed-description-color: #6B7280;
  --flow-steps-completed-bg: var(--primary);
  --flow-steps-completed-icon-color: #FFFFFF;
  --flow-steps-completed-border: var(--primary);
  --flow-steps-completed-join-color: var(--primary);
  --flow-steps-active-label-color: #3E3E3E;
  --flow-steps-active-description-color: #6B7280;
  --flow-steps-active-bg: #FFFFFF;
  --flow-steps-active-icon-color: var(--primary);
  --flow-steps-active-border: var(--primary);
  --flow-steps-active-join-color: var(--primary);
  --flow-steps-skipped-label-color: #6B7280;
  --flow-steps-skipped-description-color: #6B7280;
  --flow-steps-skipped-bg: #F2A50C;
  --flow-steps-skipped-icon-color: #FFFFFF;
  --flow-steps-skipped-border: #F2A50C;
  --flow-steps-skipped-join-color: #F2A50C;
  --flow-steps-untouched-label-color: #9CA3AF;
  --flow-steps-untouched-description-color: #9CA3AF;
  --flow-steps-untouched-bg: #FFFFFF;
  --flow-steps-untouched-icon-color: transparent;
  --flow-steps-untouched-border: #D1D5DB;
  --flow-steps-untouched-join-color: #E5E7EB;
}

:root {
  --form-text-color: var(--body-bg);
  --form-label-color: var(--text-color-3);
  --form-feedback-valid: var(--success);
  --form-feedback-invalid: var(--danger);
  --form-feedback-warning: var(--warning);
  --input-bg: #FFFFFF;
  --input-border: #D1D5DB;
  --input-border-radius: var(--border-radius);
  --input-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  --input-color: var(--text-color-2);
  --input-focus-bg: var(--input-bg);
  --input-focus-border: #98A1AE;
  --input-focus-color: var(--input-color);
  --input-disabled-bg: #F9FAFB;
  --input-disabled-border: var(--input-border);
  --input-disabled-color: #9CA3AF;
  --input-placeholder-color: var(--text-color-2);
  --input-plaintext-color: var(--body-color);
}

.form-check,
.form-switch {
  --form-check-input-bg: var(--input-bg);
  --form-check-input-border: var(--input-border);
  --form-check-input-border-radius: 0.3125rem;
  --form-check-input-focus-border: var(--input-focus-border);
  --form-check-input-checked-bg: var(--primary);
  --form-check-input-checked-border: var(--primary);
  --form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'><path fill='%23FFFFFF' d='M9.20741 0.793031C9.39488 0.980558 9.50019 1.23487 9.50019 1.50003C9.50019 1.76519 9.39488 2.0195 9.20741 2.20703L4.20741 7.20703C4.01988 7.3945 3.76557 7.49982 3.50041 7.49982C3.23524 7.49982 2.98094 7.3945 2.79341 7.20703L0.793407 5.20703C0.611249 5.01843 0.510455 4.76583 0.512733 4.50363C0.515012 4.24143 0.62018 3.99062 0.805589 3.80521C0.990997 3.6198 1.24181 3.51463 1.50401 3.51236C1.7662 3.51008 2.0188 3.61087 2.20741 3.79303L3.50041 5.08603L7.79341 0.793031C7.98094 0.60556 8.23524 0.500244 8.50041 0.500244C8.76557 0.500244 9.01988 0.60556 9.20741 0.793031Z'/></svg>");
  --form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='%23FFFFFF'/></svg>");
  --form-check-input-indeterminate-bg: #FFFFFF;
  --form-check-input-indeterminate-border: #FFFFFF;
  --form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='%2314B8A6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");
}

.form-switch {
  --form-switch-width: 2.4375rem;
  --form-switch-height: 1.5rem;
  --form-switch-bg: var(--form-check-input-border);
  --form-switch-checked-bg: var(--primary);
  --form-switch-border-radius: var(--form-switch-width);
  --form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23FFFFFF'/></svg>");
  --form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23FFFFFF'/></svg>");
  --form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='%23FFFFFF'/></svg>");
}

.form-select {
  --form-select-bg: var(--input-bg);
  --form-select-border: var(--input-border);
  --form-select-border-radius: var(--input-border-radius);
  --form-select-box-shadow: var(--input-box-shadow);
  --form-select-color: var(--input-color);
  --form-select-disabled-bg: var(--input-disabled-bg);
  --form-select-disabled-border: var(--input-disabled-border);
  --form-select-disabled-color: var(--input-disabled-color);
  --form-select-focus-border: var(--input-focus-border);
  --form-select-indicator-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%232B2B2B' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
}

.form-input-number {
  --form-input-number-of-color: #9CA3AF;
  --form-input-number-button-bg: #F3F4F6;
  --form-input-number-button-color: var(--text-color-3);
  --form-input-number-button-hover-bg: #d6d9e0;
  --form-input-number-button-hover-color: var(--form-input-number-button-color);
  --form-input-number-button-focus-bg: var(--form-input-number-button-hover-bg);
  --form-input-number-button-focus-color: var(--form-input-number-button-hover-color);
}

:root {
  --headings-margin-bottom: 0.5rem;
  --headings-line-height: 1.22;
  --headings-color: var(--text-color-2);
  --h1-font-size: 1.75rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --h4-font-size: 1.125rem;
  --h5-font-size: 1rem;
  --h6-font-size: 0.875rem;
}

:root {
  --page-heading-border: var(--border-color);
  --page-heading-color: var(--body-color);
  --page-sub-heading-color: #828282;
}

:root {
  --huge-icon-size: 9.75rem;
  --huge-icon-border-width: 1.125rem;
  --huge-icon-close-bg: var(--danger);
  --huge-icon-close-border: #f39191;
  --huge-icon-close-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 152 152'><path fill='%23FFFFFF' d='m35.8 41.149 5.3487-5.3487 34.851 34.851 34.851-34.851 5.3487 5.3487-34.851 34.851 34.851 34.851-5.3487 5.3488-34.851-34.851-34.851 34.851-5.3487-5.3488 34.851-34.851z'/></svg>");
  --huge-icon-check-bg: var(--primary);
  --huge-icon-check-border: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 12%), 100%));
  --huge-icon-check-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 152 152'><path fill='%23FFFFFF' d='m69.122 98.93-26.365-26.37 5.3495-5.3504 21.016 20.637 34.772-34.778 5.3495 5.3504z'/></svg>");
  --huge-icon-plus-bg: var(--primary);
  --huge-icon-plus-border: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 12%), 100%));
  --huge-icon-plus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 152 152'><path fill='%23FFFFFF' d='m72.218 22.931 7.5642-3.3e-5 1e-6 49.287 49.287 6.8e-5 -4e-5 7.5642-49.287 3.7e-5 -3e-6 49.287-7.5643 7e-5 4e-6 -49.287-49.287 4.3e-5 6.7e-5 -7.5643 49.287 3.1e-5z'/></svg>");
}

:root {
  --layout-login-page-bg: #FFFFFF;
  --layout-login-content-bg: #F3F4F6;
}

:root {
  --list-group-border-radius: var(--border-radius);
  --list-group-hover-bg: #F9FAFB;
  --list-group-item-border-width: 1px;
  --list-group-item-padding-x: 1rem;
  --list-group-item-padding-y: 1rem;
  --list-group-default-bg-color: #FFFFFF;
  --list-group-default-border-color: var(--border-color);
  --list-group-default-heading-color: #111827;
  --list-group-default-text-color: var(--text-color-3);
  --list-group-default-active-bg-color: hsl(var(--primary-h) var(--primary-s) 97%);
  --list-group-default-active-border-color: hsl(var(--primary-h) var(--primary-s) 78%);
  --list-group-default-active-heading-color: hsl(var(--primary-h) var(--primary-s) 17%);
  --list-group-default-active-text-color: hsl(var(--primary-h) var(--primary-s) 25%);
  --list-group-default-disabled-bg-color: var(--list-group-default-bg-color);
  --list-group-default-disabled-border-color: var(--list-group-default-border-color);
  --list-group-default-disabled-heading-color: var(--text-color-3);
  --list-group-default-disabled-text-color: var(--text-color-3);
}

:root {
  --modal-backdrop-bg: rgba(0, 0, 0, 0.2);
  --modal-bg: #FFFFFF;
  --modal-border: transparent;
  --modal-color: var(--text-color-3);
  --modal-border-radius: var(--border-radius);
  --modal-header-border: var(--border-color);
  --modal-footer-border: var(--modal-header-border);
  --modal-close-btn-color: var(--text-color-1);
  --modal-box-shadow: var(--elevation-level-4);
}

:root {
  --nav-link-color: var(--body-color);
  --nav-link-hover-color: var(--primary);
  --nav-link-disabled-color: #999999;
  --nav-tabs-border: transparent;
  --nav-tabs-items-gap: 0;
  --nav-tabs-link-padding-x: 0.75rem;
  --nav-tabs-link-padding-y: 0.25rem;
  --nav-tabs-link-border-width: 2px;
  --nav-tabs-link-hover-border: var(--nav-tabs-border);
  --nav-tabs-link-focus-border: var(--nav-tabs-border);
  --nav-tabs-link-active-color: var(--primary);
  --nav-tabs-link-active-bg: transparent;
  --nav-tabs-link-active-border: hsl(var(--primary-h) var(--primary-s) max(calc(var(--primary-l) - 6%), 0%));
  --nav-pills-link-active-color: #FFFFFF;
  --nav-pills-link-active-bg: var(--primary);
}

:root {
  --notifications-popover-bg: var(--modal-bg);
  --notifications-popover-border: var(--modal-border);
  --notifications-popover-border-radius: var(--modal-border-radius);
  --notifications-popover-color: var(--modal-color);
  --notifications-popover-box-shadow: var(--elevation-level-4);
  --notifications-popover-close-btn-color: var(--modal-close-btn-color);
  --notifications-popover-mark-as-read-btn-color: var(--success);
  --notifications-popover-delete-btn-color: var(--text-muted);
}

:root {
  --onboarding-card-alert-neutral-bg: #F3F4F6;
  --onboarding-card-alert-neutral-border: #F3F4F6;
}

:root {
  --page-heading-border: var(--border-color);
  --page-heading-color: var(--body-color);
  --page-sub-heading-color: #828282;
}

:root {
  --invoice-report-status-success-bg: var(--badge-success-bg-color);
  --invoice-report-status-success-color: var(--badge-success-text-color);
  --invoice-report-status-warning-bg: var(--badge-warning-bg-color);
  --invoice-report-status-warning-color: var(--badge-warning-text-color);
  --invoice-report-status-failure-bg: var(--badge-danger-bg-color);
  --invoice-report-status-failure-color: var(--badge-danger-text-color);
  --invoice-report-status-payment-success-bg: var(--invoice-report-status-success-bg);
  --invoice-report-status-payment-success-color: var(--invoice-report-status-success-color);
  --invoice-report-status-payment-failure-bg: var(--invoice-report-status-failure-bg);
  --invoice-report-status-payment-failure-color: var(--invoice-report-status-failure-color);
  --invoice-report-status-pending-bg: var(--badge-pending-bg-color);
  --invoice-report-status-pending-color: var(--badge-pending-text-color);
  --invoice-report-status-requires-attention-bg: var(--badge-warning-bg-color);
  --invoice-report-status-requires-attention-color: var(--badge-warning-text-color);
  --invoice-report-status-corrected-bg: #5BCC9F;
  --invoice-report-status-corrected-color: #FFFFFF;
}

:root {
  --order-event-report-status-success-bg: var(--badge-success-bg-color);
  --order-event-report-status-success-color: var(--badge-success-text-color);
  --order-event-report-status-warning-bg: var(--badge-warning-bg-color);
  --order-event-report-status-warning-color: var(--badge-warning-text-color);
  --order-event-report-status-failure-bg: var(--badge-danger-bg-color);
  --order-event-report-status-failure-color: var(--badge-danger-text-color);
}

:root {
  --product-sync-status-queued-bg: var(--badge-pending-bg-color);
  --product-sync-status-queued-color: var(--badge-pending-text-color);
  --product-sync-status-success-bg: var(--badge-success-bg-color);
  --product-sync-status-success-color: var(--badge-success-text-color);
  --product-sync-status-warning-bg: var(--badge-warning-bg-color);
  --product-sync-status-warning-color: var(--badge-warning-text-color);
  --product-sync-status-failure-bg: var(--badge-danger-bg-color);
  --product-sync-status-failure-color: var(--badge-danger-text-color);
  --product-sync-status-timeout-bg: var(--badge-danger-bg-color);
  --product-sync-status-timeout-color: var(--badge-danger-text-color);
}

:root {
  --payout-transaction-report-status-queued-bg: var(--badge-pending-bg-color);
  --payout-transaction-report-status-queued-color: var(--badge-pending-text-color);
  --payout-transaction-report-status-bookkept-bg: var(--badge-success-bg-color);
  --payout-transaction-report-status-bookkept-color: var(--badge-success-text-color);
  --payout-transaction-report-status-bookkept-to-interim-account-bg: var(--badge-warning-bg-color);
  --payout-transaction-report-status-bookkept-to-interim-account-color: var(--badge-warning-text-color);
  --payout-transaction-report-status-bookkept-with-warning-bg: var(--badge-warning-bg-color);
  --payout-transaction-report-status-bookkept-with-warning-color: var(--badge-warning-text-color);
  --payout-transaction-report-status-failed-to-bookkeep-bg: var(--badge-danger-bg-color);
  --payout-transaction-report-status-failed-to-bookkeep-color: var(--badge-danger-text-color);
  --payout-transaction-report-status-duplicate-bg: var(--badge-neutral-bg);
  --payout-transaction-report-status-duplicate-color: var(--badge-neutral-color);
  --payout-transaction-report-status-corrected-bg: #5BCC9F;
  --payout-transaction-report-status-corrected-color: #FFFFFF;
}

:root {
  --payout-summary-bookkeeping-report-status-queued-bg: var(--badge-pending-bg-color);
  --payout-summary-bookkeeping-report-status-queued-color: var(--badge-pending-text-color);
  --payout-summary-bookkeeping-report-status-bookkept-bg: var(--badge-success-bg-color);
  --payout-summary-bookkeeping-report-status-bookkept-color: var(--badge-success-text-color);
  --payout-summary-bookkeeping-report-status-bookkept-with-warning-bg: var(--badge-warning-bg-color);
  --payout-summary-bookkeeping-report-status-bookkept-with-warning-color: var(--badge-warning-text-color);
  --payout-summary-bookkeeping-report-status-failed-to-bookkeep-bg: var(--badge-danger-bg-color);
  --payout-summary-bookkeeping-report-status-failed-to-bookkeep-color: var(--badge-danger-text-color);
  --payout-summary-bookkeeping-report-status-saved-bg: var(--badge-success-bg-color);
  --payout-summary-bookkeeping-report-status-saved-color: var(--badge-success-text-color);
  --payout-summary-bookkeeping-report-status-saved-with-warning-bg: var(--badge-warning-bg-color);
  --payout-summary-bookkeeping-report-status-saved-with-warning-color: var(--badge-warning-text-color);
}

:root {
  --product-sync-filter-input-border: #E0E0E0;
  --product-sync-filter-input-placeholder-color: var(--text-color-3);
}

:root {
  --progress-bg: hsl(var(--primary-h) var(--primary-s) 86%);
  --progress-determinate: var(--primary);
  --progress-indeterminate: var(--primary);
}

:root {
  --settings-connection-scopes-bg: var(--primary);
  --settings-connection-scopes-border-radius: var(--border-radius);
  --settings-connection-scope-border: #FFFFFF;
  --settings-connection-scope-color: #FFFFFF;
  --settings-connection-scope-border-radius: var(--border-radius-sm);
}

:root {
  --settings-product-sync-link-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.97 5.354'><path fill='none' stroke='%23BBBBBB' stroke-linecap='round' stroke-linejoin='round' d='M2.47 4.646.5 2.677 2.47.707M9.5.707l1.97 1.97L9.5 4.646'/><path fill='%2301B42B' d='M7 2.707a1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1 1 1 0 0 1 1 1z'/></svg>");
}

:root {
  --table-color: var(--text-color-1);
  --table-border: var(--border-color);
  --table-thead-color: var(--body-color);
  --table-caption-color: var(--text-muted);
  --table-accent-bg: rgba(0, 0, 0, 0.03);
  --table-accent-color: var(--table-color);
  --icon-btn-in-table-color: #707070;
  --table-cell-padding-y: 1.11375rem;
  --table-cell-padding-x: 0.625rem;
  --table-condensed-cell-padding-y: 0.42625rem;
  --table-condensed-cell-padding-x: 0.625rem;
  --table-thead-cell-padding-y: 0.36375rem;
  --table-thead-cell-padding-x: var(--table-cell-padding-x);
}

:root {
  --tags-input-bg: var(--form-control-bg);
  --tags-input-color: var(--form-control-color);
  --tags-input-border: #91F2EA;
  --tags-input-item-bg: var(--primary);
  --tags-input-item-color: #FFFFFF;
  --tags-input-item-hover-bg: var(--tags-input-item-bg);
  --tags-input-item-hover-color: var(--tags-input-item-color);
  --tags-input-add-tag-btn-bg: #FFFFFF;
  --tags-input-add-tag-btn-color: var(--primary);
  --tags-input-add-tag-btn-border: var(--primary);
  --tags-input-add-tag-btn-hover-bg: #FFFFFF;
  --tags-input-add-tag-btn-hover-color: #0D9488;
  --tags-input-add-tag-btn-hover-border: #0D9488;
  --tags-input-add-tag-btn-disabled-bg: #F3F4F6;
  --tags-input-add-tag-btn-disabled-color: #9CA3AF;
  --tags-input-add-tag-btn-disabled-border: #9CA3AF;
}

:root {
  --toasts-gap: 1rem;
  --toasts-indent-x: 2rem;
  --toasts-indent-y: 2rem;
  --toast-box-shadow: var(--elevation-level-4);
  --toast-border-radius: var(--border-radius);
  --toast-default-bg: rgba(0, 0, 18, 0.8);
  --toast-default-status-color: #707070;
  --toast-default-heading-color: #FFFFFF;
  --toast-default-content-color: #E6E6E6;
  --toast-default-content-link-color: #00CFF2;
  --toast-default-close-btn-color: #E6E6E6;
  --toast-default-autoclose-progress-bg: rgba(255, 255, 255, 0.25);
  --toast-default-color-scheme: dark;
  --toast-success-bg: var(--toast-default-bg);
  --toast-success-status-color: #10B981;
  --toast-success-heading-color: var(--toast-default-heading-color);
  --toast-success-content-color: var(--toast-default-content-color);
  --toast-success-content-link-color: var(--toast-default-content-link-color);
  --toast-success-close-btn-color: var(--toast-default-close-btn-color);
  --toast-success-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-success-color-scheme: var(--toast-default-color-scheme);
  --toast-info-bg: var(--toast-default-bg);
  --toast-info-status-color: #E6E6E6;
  --toast-info-heading-color: var(--toast-default-heading-color);
  --toast-info-content-color: var(--toast-default-content-color);
  --toast-info-content-link-color: var(--toast-default-content-link-color);
  --toast-info-close-btn-color: var(--toast-default-close-btn-color);
  --toast-info-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-info-color-scheme: var(--toast-default-color-scheme);
  --toast-warning-bg: var(--toast-default-bg);
  --toast-warning-status-color: #F2A50C;
  --toast-warning-heading-color: var(--toast-default-heading-color);
  --toast-warning-content-color: var(--toast-default-content-color);
  --toast-warning-content-link-color: var(--toast-default-content-link-color);
  --toast-warning-close-btn-color: var(--toast-default-close-btn-color);
  --toast-warning-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-warning-color-scheme: var(--toast-default-color-scheme);
  --toast-error-bg: var(--toast-default-bg);
  --toast-error-status-color: #E62222;
  --toast-error-heading-color: var(--toast-default-heading-color);
  --toast-error-content-color: var(--toast-default-content-color);
  --toast-error-content-link-color: var(--toast-default-content-link-color);
  --toast-error-close-btn-color: var(--toast-default-close-btn-color);
  --toast-error-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-error-color-scheme: var(--toast-default-color-scheme);
}

:root {
  --tooltip-bg: #FFFFFF;
  --tooltip-border: transparent;
  --tooltip-color: #111827;
  --tooltip-divider-color: var(--table-border);
  --tooltip-box-shadow: var(--elevation-level-2);
}

:root {
  --user-profile-dropdown-bg: var(--dropdown-bg);
  --user-profile-dropdown-color: var(--dropdown-color);
  --user-profile-dropdown-name-color: var(--text-color-1);
  --user-profile-dropdown-link-color: var(--text-color-1);
  --user-profile-dropdown-divider-color: var(--dropdown-divider-color);
  --user-profile-dropdown-box-shadow: var(--elevation-level-4);
}